import React from 'react'
import Blog from './Components/Blog'
import Body from './Components/Body'
import Features from './Components/Features'
import Footer from './Components/Footer'
import Header from './Components/Header'
import HomeScreen from './Components/HomeScreen'

import Spelize from './Components/Spelize'




const App = () => {
  return (
    <div>
      <Header />
      <HomeScreen />
      <Body />
      <br />
      <br />
      <br />
      <Spelize />
      <Features />
      <Blog />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default App